import Vue from 'vue'
import VueRouter from 'vue-router'
import { Trans } from '@/plugins/Translation'

function load(component) {
  // '@' is aliased to src/components
  return () => import(`@/views/${component}.vue`)
}

Vue.use(VueRouter)

const routes = [
  {
    path: '/:locale',
    component: {
      render (h) {
        return h('RouterView');
      }
    },
    beforeEnter: Trans.routeMiddleware,
    children: [
      {
        path: '',
        name: 'Home',
        redirect: 'projects',
      },
      {
        path: 'projects',
        name: 'Projects',
        component: load('Projects')
      },
      {
        path: 'statement',
        name: 'Statement',
        component: load('Statement')
      },
      {
        path: 'cv',
        name: 'Curriculum',
        component: load('Cv')
      },
      {
        path: 'links',
        name: 'Links',
        component: load('Page')
      },
      {
        path: 'contact',
        name: 'Contact',
        component: load('Contact')
      },
      {
        path: 'project/:slug',
        name: 'Project',
        component: load('Project')
      }
    ]
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  if (to.path === '/') {
    next(`/${Trans.defaultLocale}`)
  }
  else next()
})

router.afterEach(() => {
  window.scrollTo(0, 0)
})

export default router
