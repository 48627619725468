<template>
  <div id="app">
		<HeaderNav></HeaderNav>
    <router-view class="view" />
  </div>
</template>

<script>
import HeaderNav from '@/components/Header.vue'

export default {
  components: {
    HeaderNav
  },
  beforeCreate () {
    this.$store.dispatch('content/setup')
  }
}
</script>

<style lang="scss">
  @import 'assets/common/reset.module.css';
  @import url('https://fonts.googleapis.com/css?family=Muli:200,200i,300,300i,400,400i,600,600i,700,700i&display=swap');

  * {
    font-family: 'Muli', sans-serif !important;
  }

  .view {
    padding: 90px 50px 50px 50px;
    max-width: 1200px;
    margin: 0 auto;
  }
</style>
