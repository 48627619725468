import Vue from 'vue'
import Vuex from 'vuex'
import content from './content'

Vue.use(Vuex)

export default new Vuex.Store({
  state () {
    return {
      locale: undefined
    }
  },
  getters: {
    currentLang (state) {
      return state.locale === 'es' ? 'es-es' : 'ca'
    },
  },
  mutations: {
    setLocale (state, payload) {
      state.locale = payload
    }
  },
  actions: {
  },
  modules: {
    content
  }
})
