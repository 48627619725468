import Vue from 'vue'
import App from './App.vue'
import VueImg from 'v-img';
import { i18n } from './i18n'
import Prismic from './plugins/Prismic'
import router from './router'
import store from './store'
import { Trans } from './plugins/Translation'

Vue.prototype.$i18nRoute = Trans.i18nRoute.bind(Trans)

Vue.config.productionTip = false

Vue.use(VueImg)
Vue.use(Prismic)

new Vue({
  i18n,
  router,
  store,
  render: h => h(App)
}).$mount('#app')
