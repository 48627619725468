import Prismic from '@prismicio/client'
import prismicDom from 'prismic-dom'

export const prismicClient = Prismic.client(
    'https://marcangles.cdn.prismic.io/api/v2',
    { accessToken: 'MC5ZQ2UyblJJQUFDTUFWeUZT.77-9d--_ve-_ve-_ve-_vRbvv73vv70K77-9bu-_ve-_ve-_ve-_vXIGLVLvv71JBu-_ve-_vS7vv70y77-9DWDvv70' }
)

export default {
    install (Vue, options) {
        Vue.mixin({
            beforeCreate () {
                this.$prismic = {
                    client: prismicClient,
                    dom: prismicDom
                };
            }
        })
    }
}
