import { prismicClient } from '../plugins/Prismic'

export default {
    namespaced: true,
    state () {
        return {
            currentProject: null,
            projects: [],
            statement: [],
            contact: [],
            cv: [],
            pendingPromises: []
        }
    },
    getters: {
        getProjects (state, getters, rootState, rootGetters) {
            return state.projects.filter(doc => doc.lang === rootGetters.currentLang)
        },
        getCurrentProject (state, getters, rootState, rootGetters) {
            return state.projects.find(doc => {
                return doc.lang === rootGetters.currentLang &&
                    doc.slugs.includes(state.currentProject)
            })
        },
        getStatement (state, getters, rootState, rootGetters) {
            return state.statement.find(doc => doc.lang === rootGetters.currentLang)
        },
        getContact (state, getters, rootState, rootGetters) {
            return state.contact.find(doc => doc.lang === rootGetters.currentLang)
        },
        getCv (state, getters, rootState, rootGetters) {
            return state.cv.find(doc => doc.lang === rootGetters.currentLang)
        }
    },
    actions: {
        setup ({ dispatch }) {
            dispatch('_getPrismicContent')
        },
        async _getPrismicContent ({ commit }) {
            try {
                const docs = await prismicClient.query('', { lang: '*' })
                commit('setContent', docs.results)
            } catch (e) {
                console.error(e)
            }
        },
        setCurrentProject ({ commit } ,slug) {
            commit('setCurrentProject', slug)
        }
    },
    mutations: {
        setContent (state, payload) {
            const { statement, projects, contact, cv } = payload.reduce((acc, curr) => {
                switch (curr.type) {
                    case 'project':
                        acc.projects.push(curr)
                        break
                    case 'statement':
                        acc.statement.push(curr)
                        break
                    case 'contact':
                        acc.contact.push(curr)
                        break
                    case 'curriculum':
                        acc.cv.push(curr)
                }
                return acc
            }, { statement: [], projects: [], contact: [], cv: [] })
            state.projects = projects
            state.statement = statement
            state.contact = contact
            state.cv = cv
        },
        setCurrentProject (state, slug) {
            state.currentProject = slug
        }
    }
}
