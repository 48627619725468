<template>
	<div id="nav">
		<div class="fw-wrapper">
			<h1 class="nav-brand"><router-link :to="$i18nRoute({ name: 'Home' })">Marc Anglès</router-link></h1>
			<div class="nav-links">
				<router-link class="nav-link" :to="$i18nRoute({ name: 'Projects' })">{{$t("NAV.PROJECTS")}}</router-link>
				<router-link class="nav-link" :to="$i18nRoute({ name: 'Statement' })">{{$t("NAV.STATEMENT")}}</router-link>
				<router-link class="nav-link" :to="$i18nRoute({ name: 'Curriculum' })">{{$t("NAV.CURRICULUM")}}</router-link>
				<!-- <router-link class="nav-link" to="/links">{{$t("NAV.LINKS")}}</router-link> -->
				<router-link class="nav-link" :to="$i18nRoute({ name: 'Contact' })">{{$t("NAV.CONTACT")}}</router-link>
			</div>
			<div class="locale-switcher">
				<select
          class="nav-info-link"
          v-model="selectedLocale"
        >
					<option
            class="nav-info-link"
            v-for="lang in supportedLocales"
            :key="lang"
            :value="lang"
          >{{ lang }}</option>
				</select>
			</div>
		</div>
	</div>
</template>

<script>
import { Trans } from '@/plugins/Translation'

export default {
  name: 'HeaderNav',
  data () {
    return {
      selectedLocale: Trans.currentLocale
    }
  },
  computed: {
    supportedLocales () {
      return Trans.supportedLocales
    }
  },
  watch: {
    selectedLocale (locale) {
      if (this.$i18n.locale !== locale) {
        const { location } = this.$router.resolve({ params: { locale } })
        Trans.changeLocale(locale).then(() => {
          this.$router.push(location)
        })
      }
    },
    '_i18n.locale' (locale) {
      this.selectedLocale = locale
    }
  }
}
</script>

<style lang="scss">
  @import './../scss/colors.scss';
  #nav {
    position: fixed;
    top: 0;
    width: calc(100vw - 100px);
    height: 60px;
    background-color: $color-light;
    padding: 0px 50px;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    z-index: 9999;
  }
  #nav>.fw-wrapper {
    flex-grow: 1;
    max-width: 1200px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }
  .nav-brand {
    font-size: 1.3rem;
    font-weight: 600;
    color: $color-black;
    text-decoration: none;
  }
  .nav-brand>a,.nav-brand>a:hover, .nav-brand>a:active, .nav-brand>a:visited {
    color: $color-black;
    text-decoration: none;
  }
  .nav-links {
    flex-grow: 2;
    display: flex;
    justify-content: space-evenly;
  }
  .nav-link {
    font-weight: 400;
    // text-transform: uppercase;
    // font-size: .9rem;
    text-decoration: none;
    color: $color-grey;
    margin-bottom: -1px;
    border-bottom: 1px solid transparent;
    transition: border-bottom .3s ease-in-out;
  }
  .nav-link:hover {
    color: $color-black;
    border-bottom: 1px solid $color-black;
  }
</style>
